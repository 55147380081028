<template>
  <div>
    <van-nav-bar
      title="轻松赚钱"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <img class="head" src="../assets/makemoney.png" alt="" />
    <div class="title">
      <img src="../assets/fenxiang.png" alt="" />
      <div>分享邀请，<span>200到账</span></div>
    </div>
    <ul class="mini">
      <li><span></span>点击底部菜单栏“推广邀请”</li>
      <li>
        <span></span
        >生成您的专属海报，发送给好友每位好友完成新用户注册，您的余额瞬间+10元，好友完成企业认证，您的余额再加190元。
      </li>
    </ul>
    <div class="title">
      <img src="../assets/tu2.png" alt="" />
      <div>没用简历“变废为宝”，<span>一份20元</span></div>
    </div>
    <ul class="mini cc">
      <li><span></span>登录萝卜猎手PC端</li>
      <li class="sy"><span></span>点击“简历公海”--“上传简历”</li>
      <li class="sy">
        <span></span>按指引完成简历上传。每次简历上传成功，余额瞬间+20元！
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  methods: {
    onClickLeft() {
      Toast("返回");
    },
  },
};
</script>

<style scoped>
.head {
  box-sizing: border-box;
  width: 100%;
  padding: 0 0.2rem;
}
.title {
  display: flex;
  padding: 0.2rem 0;
  font-size: 0.36rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.6rem;
  background: url("../assets/yuan.png") no-repeat center center;
  background-size: 40%;
}
.title img {
  margin-left: 0.5rem;
  margin-right: 0.18rem;
  width: 0.7rem;
  height: 0.7rem;
}
.title div span {
  color: #f55814;
}
.mini {
  background: url("../assets/tu1.png") no-repeat 0.38rem 0.3rem;
  background-size: 2.35rem;
}
.mini li {
  transform: translateY(-0.4rem);
  margin-left: 2.52rem;
  width: 4.5rem;
  display: inline-block;
  font-size: 0.25rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.5rem;
}
.cc {
  background: url("../assets/tu3.png") no-repeat 0.38rem 0.3rem;
  background-size: 2.35rem;
}
.mini span {
  /* transform: translate(0.1rem, -0.05rem); */
  display: inline-block;
  margin-right: 0.2rem;
  width: 0.14rem;
  height: 0.14rem;
  background: #f55814;
  border-radius: 50%;
}
/* 此处有bug强制移动 */
.sy {
  position: relative;
  top: -0.4rem;
}
</style>